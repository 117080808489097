
import {computed, defineComponent, onMounted, onUpdated, ref} from "vue";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {ICase} from "@/core/entity/ICase";
import store from "@/store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import ProviderForm from "@/views/provider/ProviderForm.vue";
import {ModelAddress} from "@/core/entity/IAddress";
import router from "@/router";

export default defineComponent({
  name: "AddProvider",
  components: {ProviderForm},
  setup() {
    const model = ref({
      name: '',
      address: new ModelAddress(),
      npi: '',
      type: 'CLINIC',
      status: 'COMPLETED',
      accNum: '',
      diagId1: '',
      diagId2: '',
      diagId3: '',
      diagId4: '',
      diag1Code: '',
      diag2Code: '',
      diag3Code: '',
      diag4Code: '',
      fromDt: new Date().getTime(),
      toDt: new Date().getTime(),
      mrNum: '',
      diagSummary: '',
    })
    const caze = computed<ICase>(() => store.state.CaseModule.case);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Add Provider', [
        {link: true, router: '/policies/' + caze?.value?.policy?.id, text: 'View Policy'},
        {link: true, router: '/cases/' + caze?.value?.id, text: caze?.value?.number},
        {link: false, router: '', text: ' Provider'}
      ])
    })
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Add Provider', [
        {link: true, router: '/policies/' + caze?.value.policy?.id, text: 'View Policy'},
        {link: true, router: '/cases/' + caze?.value?.id, text: 'View Case'},
        {link: false, router: '', text: 'Add Provider'}
      ])
    })
    return {
      ...LoadEntity(Actions.LOAD_CASE),
      caze,
      model,
    }
  },
  methods: {
    onSaved() {
      router.push({
        name: 'caseOverview',
        params: {id: this.caze.id}
      })
    }
  }
})
